@font-face {
  font-family: novelgothicnormal;
  src: url("novelgothic_normal.7daf4c60.woff") format("woff"), url("novelgothic_normal.46d6c656.ttf") format("truetype"), url("novelgothic_normal.06ef9cb4.svg#novelgothicnormal") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: wagnastyregular;
  src: url("wagnasty.af01d4c7.woff") format("woff"), url("wagnasty.2b70f858.ttf") format("truetype"), url("wagnasty.56f2240a.svg#wagnastyregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: special_eliteregular;
  src: url("specialelite.2d65af32.woff") format("woff"), url("specialelite.d702a0a2.ttf") format("truetype"), url("specialelite.38621c03.svg#special_eliteregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*# sourceMappingURL=index.b70e1666.css.map */
