/* Generated by Font Squirrel (http://www.fontsquirrel.com) on May 28, 2014 */



@font-face {
    font-family: 'novelgothicnormal';
    src: url('novelgothic_normal.woff') format('woff'),
         url('novelgothic_normal.ttf') format('truetype'),
         url('novelgothic_normal.svg#novelgothicnormal') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'wagnastyregular';
    src: url('wagnasty.woff') format('woff'),
         url('wagnasty.ttf') format('truetype'),
         url('wagnasty.svg#wagnastyregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'special_eliteregular';
    src: url('specialelite.woff') format('woff'),
         url('specialelite.ttf') format('truetype'),
         url('specialelite.svg#special_eliteregular') format('svg');
    font-weight: normal;
    font-style: normal;

}